import { isOpen } from '../_variables';
import { getOffsetLeft } from '../_functions';

export default class PinMap {
  constructor (prop) {
    this.AllPinBox = document.querySelectorAll(prop.target);
  }

  init () {
    if (this.AllPinBox.length) {
      for (const pinBox of this.AllPinBox) {
        const pin = pinBox.querySelector('.p-map-pin__pin');
        const infoWindow = pinBox.querySelector('.p-map-pin__box');
        const btnX = pinBox.querySelector('.p-map-pin__box-x');

        // open infoWindow
        pin.addEventListener('click', e => {
          if (!pinBox.classList.contains(isOpen)) {
            // close the infowindow which is opening
            const boxPinOpen = document.querySelector(`.p-map-pin.${isOpen}`);

            if (boxPinOpen) {
              boxPinOpen.classList.remove(isOpen);
            }

            // open info window
            pinBox.classList.add(isOpen);

            // adjust position of box info
            infoWindow.style.top = `${Math.floor(infoWindow.offsetHeight) * -1}px`;
            setLeftToPin(pin, infoWindow);
          }
        });

        // close infoWindow
        btnX.addEventListener('click', e => {
          pinBox.classList.remove(isOpen);
          infoWindow.removeAttribute('style');
        });
      }

      window.addEventListener('resize', () => {
        // adjust left position of the pin which is opening
        const boxPinOpen = document.querySelector(`.p-map-pin.${isOpen}`);
        if (boxPinOpen) {
          const infoWindow = boxPinOpen.querySelector('.p-map-pin__box');
          const pin = boxPinOpen.querySelector('.p-map-pin__pin');
          setLeftToPin(pin, infoWindow);
        }
      });

      function setLeftToPin(pin, infoWindow) {
        if (getOffsetLeft(pin) >= 78) {
          infoWindow.style.left = `${((infoWindow.offsetWidth / 2) - (Math.floor(pin.offsetWidth) / 2)) * -1}px`;
        } else { // when the pin is very near the screen boundary
          infoWindow.style.left = 0;
        }
      }
    }
  }
}