import { isActive, breakpointLG } from '../_variables';

export default class FilterProduct {
  constructor (prop) {
    this.target = prop.target;
    this.textFilters = document.querySelectorAll(this.target);
  }

  init () {
    if (this.textFilters.length) {
      const listProduct = document.querySelectorAll('.p-top-list-product__item');

      for (const textFilter of this.textFilters) {
        textFilter.addEventListener('click', (e) => {
          e.preventDefault();
          const self = e.target;
          if (!self.classList.contains(isActive)) {
            // reset actived
            const textFilter_ACTIVED = document.querySelector(`${this.target}.is-active`);
            if (textFilter_ACTIVED) {
              textFilter_ACTIVED.classList.remove(isActive);
            }

            // add active
            self.classList.add(isActive);

            // filter
            const target = self.dataset.target;
            if (target === 'all') {
              for (const item of listProduct) {
                item.removeAttribute('style');
              }
            } else {
              for (const item of listProduct) {
                item.removeAttribute('style');
                const tagTarget = item.querySelector(`.${target}`);
                if (!tagTarget) {
                  item.style.display = 'none';
                }
              }
            }
          }
        });
      }

      // start first-item trigger click
      this.textFilters[0].click();
    }
  }
}