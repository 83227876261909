import 'babel-polyfill';
import Menu from './modules/Menu';
import ButtonPagetop from './modules/ButtonPagetop';
import LinkScroll from './modules/LinkScroll';
import MainvisualTop from './modules/MainvisualTop';
import FilterProduct from './modules/FilterProduct';
import PinMap from './modules/PinMap';
import SchoolNav from './modules/SchoolNav';

const menu = new Menu();

const buttonPageTop = new ButtonPagetop({
  target: '.c-button-pagetop'
});

const linkScroll = new LinkScroll({
  target: '.c-link-scroll'
});

const mainvisualTop = new MainvisualTop({
  target: '#mainvisual-toppage'
});

const filterProduct = new FilterProduct({
  target: '.p-top-filter__text'
});

const pinMap = new PinMap({
  target: '.p-map-pin'
});

const schoolNav = new SchoolNav({
  target: '.p-school-nav__link'
});

menu.init();
buttonPageTop.init();
linkScroll.init();
mainvisualTop.init();
filterProduct.init();
pinMap.init();
schoolNav.init();