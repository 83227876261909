import { isActive } from '../_variables';

export default class SchoolNav {
  constructor (prop) {
    this.AllLink = document.querySelectorAll(prop.target);
  }

  init () {
    if (this.AllLink.length) {
      for (const link of this.AllLink) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const self = e.target;

          // set active
          if (!self.classList.contains(isActive)) {
            // remove prev actived
            const linkActived = document.querySelector('.p-school-nav__link.is-active');
            if (linkActived) {
              linkActived.classList.remove(isActive);
            }

            // set active
            self.classList.add(isActive);
          }
        });
      }
    }
  }
}