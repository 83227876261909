import Swiper from 'swiper';

export default class MainvisualTop {
  constructor (prop) {
    this.target = prop.target;
    this.MainvisualTop = document.querySelector(prop.target);
  }

  init () {
    if (this.MainvisualTop) {
      const mainvisualSlider = new Swiper('.swiper-container', {
        slidesPerView: 1,
        loop: true,
        loopAdditionalSlides: 1,
        centeredSlides: true,
        spaceBetween: 30,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.p-top-mainvisual-slider__pagination',
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 1
          },
          1069: {
            slidesPerView: 'auto'
          }
        }
      });
    }
  }
}