import { isShow } from '../_variables';

export default class ButtonPagetop {
  constructor (prop) {
    this.buttonPagetop = document.querySelector(prop.target);
  }

  init () {
    if (this.buttonPagetop) {
      const pointShowButton = 300;
      window.addEventListener('scroll', () => {
        const winTop = window.scrollY || window.pageYOffset;

        if (winTop >= pointShowButton) {
          this.buttonPagetop.classList.add(isShow);
        } else {
          this.buttonPagetop.classList.remove(isShow);
        }
      });
    }
  }
}