import { isShow, breakpointMD } from '../_variables';

export default class Menu {
  constructor () {
    this.btnOpenMenu = document.getElementById('button-open-menu');
    this.btnCloseMenu = document.getElementById('button-close-menu');
    this.Menu = document.getElementById('menu');
  }

  openMenu() {
    this.btnOpenMenu.style.display = 'none';
    this.btnCloseMenu.style.display = 'block';
    this.Menu.classList.add(isShow);
  }

  closeMenu() {
    this.btnCloseMenu.style.display = 'none';
    this.btnOpenMenu.style.display = 'block';
    this.Menu.classList.remove(isShow);
  }

  resetMenu() {
    if (window.matchMedia(`(min-width: ${breakpointMD}px)`).matches) {
      this.btnCloseMenu.removeAttribute('style');
      this.btnOpenMenu.removeAttribute('style');
      this.Menu.classList.remove(isShow);
    }
  }

  init () {
    // open side menu
    this.btnOpenMenu.addEventListener('click', () => {
      this.openMenu();
    });

    // close side menu
    this.btnCloseMenu.addEventListener('click', () => {
      this.closeMenu();
    });

    // reset header-top when resize >= 1069px
    window.addEventListener('resize', () => {
      this.resetMenu();
    });
  }
}