import { breakpointMD } from '../_variables';
import { getOffsetTop, scrollAnimate } from '../_functions';

export default class LinkScroll {
  constructor (prop) {
    this.AllLink = document.querySelectorAll(prop.target);
  }

  init () {
    if (this.AllLink.length) {
      for (const link of this.AllLink) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const headerHeight = window.matchMedia(`(max-width: ${breakpointMD - 1}px)`).matches ? document.getElementById('header-top').offsetHeight : 0;
          const target = e.target.getAttribute('href');
          const targetTop = target === '#' ? 0 : (getOffsetTop(document.querySelector(target))) - headerHeight;
          scrollAnimate(targetTop);
        });
      }
    }
  }
}